export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "addNode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Node"])},
        "nodeName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Node Name"])},
        "nodeRoles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Node Roles"])},
        "conflict": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Conflict with a existing node ", _interpolate(_named("name")), "."])}
      },
      "zh": {
        "addNode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["添加节点"])},
        "nodeName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["节点名称"])},
        "nodeRoles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["节点角色"])},
        "conflict": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["与已有节点重名 ", _interpolate(_named("name"))])}
      }
    }
  })
}
