export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "confirmDelete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure to delete this node ?"])}
      },
      "zh": {
        "confirmDelete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您是否确认要删除此节点？"])}
      }
    }
  })
}
