export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "createResource": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Resource Package"])},
        "bastionUsage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KuboardSpray can access Kubernetes Cluster Nodes through bastion."])},
        "setSshParam": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Bastion is not enabled, please set SSH params in ", _interpolate(_named("tabName")), " tab."])},
        "goToResourcePage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It's about to go to Resource Package Download page, you are going to lose the unsaved content on this page, do you confirm?"])}
      },
      "zh": {
        "createResource": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["添加资源包"])},
        "bastionUsage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KuboardSpray 可以通过跳板机或堡垒机访问将要安装 K8S 集群的目标节点。"])},
        "setSshParam": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["未使用跳板机或堡垒机时，请在 ", _interpolate(_named("tabName")), " 标签页设置 SSH 连接参数。"])},
        "goToResourcePage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["此操作将跳转到资源包导入页面，您将丢失当前页面中未保存的内容，是否继续？"])}
      }
    }
  })
}
