export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "addSshKey": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Private Key"])}
      },
      "zh": {
        "addSshKey": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["添加私钥"])},
        "ansible_host_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KuboardSpray 连接该主机时所使用的主机名或 IP 地址"])},
        "default_value": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["默认值：", _interpolate(_named("default_value")), " （继承自 K8s 集群标签页中的设置）"])}
      }
    }
  })
}
