export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "sshcommon": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["SSH Params (apply to node ", _interpolate(_named("nodeName")), ")"])},
        "etcd": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["ETCD params (scope: node ", _interpolate(_named("nodeName")), ")"])},
        "etcd_member_name_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please input etcd_member_name"])},
        "etcd_member_name_conflict": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["etcd_member_name conflict with that in node ", _interpolate(_named("nodeName"))])},
        "roles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Node Role"])},
        "roleDescription": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Node Role (scope: node ", _interpolate(_named("nodeName")), ")"])},
        "requiresAtLeastOneRole": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Requires at least one role"])}
      },
      "zh": {
        "sshcommon": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["SSH 连接参数（适用范围：节点 ", _interpolate(_named("nodeName")), "）"])},
        "etcd": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["ETCD 参数（适用范围：节点 ", _interpolate(_named("nodeName")), "）"])},
        "etcd_member_name_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请填写 ETCD 成员名称"])},
        "etcd_member_name_conflict": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["ETCD成员名称与节点 ", _interpolate(_named("nodeName")), " 的ETCD成员名称冲突"])},
        "roles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["节点角色"])},
        "roleDescription": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["节点角色（适用范围：节点 ", _interpolate(_named("nodeName")), "）"])},
        "requiresAtLeastOneRole": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["至少需要一个角色"])}
      }
    }
  })
}
